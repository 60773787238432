import React, { Component } from "react"
import { Field, ErrorMessage } from "formik"
import MaskedInput from "react-text-mask"

class Zip extends Component {

  status = ""

  validate = values => {
    let self = this
    let val = values.replace(/[^0-9]/g, "")

    if (!val) {
      return "Required"
    }

    if (val.length < 5) {
      return "Must be 5 chars"
    }

    if ((val.length === 5 && this.status !== 200) || this.props.errors.zip) {
      return fetch(`https://api.zippopotam.us/us/${val}`).then(res => {
        let errors

        if (res.status !== 200) {
          errors = "Not valid zip"
        } else {
          this.status = 200
        }

        if (errors) {
          console.log(values)
          throw errors
        } else if(self.props.setCityState){
          res.json().then(function(data) {
          self.props.setHomeCityState(data)
          })
        }
      })
    }
  }

  render() {
    return (
      <div className="ttg-field">
        {
          this.props.hideLabel ? 
          null : 
          <label htmlFor="zip" name="zip">
            { this.props.labelText || "Zip" }
          </label>  
        }
        <Field
          name="zip"
          validate={this.validate}
          render={({ field }) => (
            <MaskedInput
              {...field}
              className={ `${this.props.className || 'field'} ${
                this.props.errors.zip && this.props.touched.zip ? this.props.errorClass || "has-error" : ""
              }`}
              id="zip"
              mask={[/[0-9]/, /\d/, /\d/, /\d/, /\d/]}
              placeholder={ this.props.placeholder || "Zip code"}
              type="text"
              guide={false}
              onChange={e => {
                this.props.handleChange(e)
                this.props.setField(e)
              }}
            />
          )}
        />
        {
          this.props.noErrorMessage ?
          null :
          <ErrorMessage data-testid="zip" name="zip" component="div" className="field-error" />
        }
      </div>
    )
  }
}
export default Zip;
